.tech:hover .tech__symbol {
  border-width: 0;
  transform: scale(1.75);
  border-color: transparent;
}
.contact:hover {
  transform: translateX(0.5em);
}
.name__main {
  transition: all ease 200ms;
  will-change: transform;
}
.hobby:hover .name__main {
  transform: translateY(-10px);
  opacity: 0;
}
.name__alternative {
  transition: all ease 200ms;
  transform: translateY(-10px);
  width: 100%;
  will-change: transform;
}
.hobby:hover .name__alternative {
  opacity: 1;
  transform: translateY(-20px);
  visibility: visible;
}
.work:hover .work__logo {
  filter: grayscale(0);
}
